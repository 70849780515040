<template>
    <div class="container">
        <div class="col-wrap header">
            <div class="bg bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h1>ITRAX NEWS</h1>
                    <h3>十七年成长突破，见证进取的里程碑</h3>
                    <p>Seventeen years’ development for remarkable progress</p>
                </div>
            </div>
        </div>
        <div class="col-wrap news">
            <div class="wrap-content">
                <ul class="content">
                    <li v-for="item in news" :key="item.id">
                        <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_520,h_346'" />
                        <div>
                            <h1>{{ item.title }}</h1>
                            <p v-html="item.desc"></p>
                            <a :href="'/news/detail?id=' + item.id">查看全文<i class="iconfont icon-next"></i></a>
                        </div>
                    </li>
                </ul>

                <div class="load-more" v-if="!ended">
                    <a @click="loadData" :class="loading ? 'disabled' : ''"><i v-if="loading" class="iconfont icon-loading"></i>点击查看更多内容</a>
                </div>
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
export default {
    name: "pc-news",
    components: { Nav, Footer },
    data() {
        return {
            loading: false,
            resourceBaseUrl: settings.resourceBaseUrl,
            news: [],
            page: 0,
            ended: false,
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            this.$http
                .get(`/api/news/list?page=${this.page + 1}`)
                .then((res) => {
                    this.page++;
                    this.news = this.news.concat(res.data);
                    this.ended = res.data.length < 6;
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");
@import url("../../../common/css/icon.css");

.load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
}

.load-more a i {
    margin-right: 5px;
}
.load-more a:hover {
    background: #0144b1;
    color: #fff;
}

.news li {
    width: 100%;
    display: flex;
    margin: 40px 0;
}

.news li div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 40px;
}

.news li div a:hover {
    background: #0144b1;
    color: #fff;
}

.news li div a:hover i {
    color: #fff;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/case/banner-bg.jpg") center bottom;
        height: 500px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .news li div h1 {
        font-size: 16px;
        color: #333333;
        margin: 15px 0;
    }
    .news li div p {
        color: #333333;
        font-size: 14px;
        line-height: 20px;
    }
    .news li div a {
        width: 130px;
        height: 35px;
        border-radius: 20px;
        border: 1px #bfbfbf solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 14px;
        margin: 15px 0;
        cursor: pointer;
    }
    .news li div a i {
        color: #333333;
        margin-left: 10px;
        font-size: 10px;
    }
    .news li img {
        width: 400px;
        height: 266px;
    }

    .load-more a {
        width: 205px;
        height: 40px;
        border-radius: 20px;
        border: 1px #bfbfbf solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 14px;
        margin: 15px 0;
        cursor: pointer;
    }

    .news .wrap-content .content {
        width: 1000px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/case/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .news li div h1 {
        font-size: 16px;
        color: #333333;
        margin: 15px 0;
    }
    .news li div p {
        color: #333333;
        font-size: 15px;
        line-height: 20px;
    }
    .news li div a {
        width: 130px;
        height: 35px;
        border-radius: 20px;
        border: 1px #bfbfbf solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 14px;
        margin: 15px 0;
        cursor: pointer;
    }
    .news li div a i {
        color: #333333;
        margin-left: 10px;
        font-size: 10px;
    }
    .news li img {
        width: 400px;
        height: 266px;
    }

    .load-more a {
        width: 225px;
        height: 45px;
        border-radius: 25px;
        border: 1px #bfbfbf solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 15px;
        margin: 15px 0;
        cursor: pointer;
    }

    .news .wrap-content .content {
        width: 1280px;
        margin: 0 auto;
    }
}
</style>
